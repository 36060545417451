const inBrowser = typeof window !== 'undefined'

function isDef (val) {
  return val !== undefined && val !== null && val !== ''
}

function isDate (val) {
  return Object.prototype.toString.call(val) === '[object Date]' && !Number.isNaN(val.getTime())
}

function isMobile (value) {
  value = value.replace(/[^-|\d]/g, '')
  return /^((\+86)|(86))?(1)\d{10}$/.test(value) || /^0[0-9-]{10,13}$/.test(value)
}

function isNumeric (val) {
  return typeof val === 'number' || /^\d+(\.\d+)?$/.test(val)
}

function isAndroid () {
  return inBrowser ? /android/.test(navigator.userAgent.toLowerCase()) : false
}

function isIOS () {
  return inBrowser ? /ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) : false
}

export {
  isDef,
  isDate,
  isMobile,
  isNumeric,
  isAndroid,
  isIOS
}
