<template>
  <div class="account-wrap">
    <div class="account-item">
      <MyField v-model="state.userInfo.name" required label="姓名" placeholder="请输入姓名" />
    </div>
    <div class="account-item">
      <MyField v-model="state.userInfo.company_name" required label="工作单位" @on-change="changeHandle" placeholder="请输入工作单位" />
      <div class="field-list" :class="state.showKeyword && state.fieldList.length ? 'show' : 'hide'">
        <template v-for="item in state.fieldList" :key='item'>
          <div @click="fieldClick(item)" class="field-item">{{ item.company_name }}</div>
        </template>
      </div>
    </div>
    <div v-if="showForm === '1'">
      <div class="account-item">
        <MyField
          v-model="state.userInfo.department"
          required
          readonly
          label="科室"
          :columns="office"
          placeholder="请选择科室"
          title="请选择科室"
        />
      </div>
      <div class="account-item">
        <MyField
          v-model="state.userInfo.position"
          required
          readonly
          label="职称"
          placeholder="请选择职称"
          :columns="position"
          title="请选择职称"
        />
      </div>
    </div>
    <div v-if="showForm === '2'">
      <div class="account-item">
        <MyField
         v-model="state.userInfo.department"
         required
         readonly
         label="部门"
         placeholder="请选择部门"
         :columns="department"
          title="请选择部门"
        />
      </div>
      <div class="account-item">
        <MyField
          v-model="state.userInfo.position"
          required
          readonly
          label="职位"
          placeholder="请选择职位"
          :columns="part"
          title="请选择职位"
        />
      </div>
    </div>
    <div class="account-item">
      <MyField
        v-model="state.email"
        label="邮箱"
        placeholder="请输入邮箱"
      />
    </div>
    <div class="btn-wrap" @click="updateHandle">更新</div>
  </div>
</template>

<script>
import MyField from '@/components/my-input'
import { computed, reactive, watch } from 'vue'
import { getCompanys, putUserBasic } from '@/api/'
import { debounce, deleteObjEmptyData } from '@/utils/'
import { isDef } from '@/utils/validate'
import { Toast } from 'vant'
import { office, position, department, part } from '@/config/'
import { useRouter } from 'vue-router'
export default {
  components: {
    MyField
  },
  setup () {
    const router = useRouter()
    const localUserInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    const state = reactive({
      userInfo: {
        name: localUserInfo.name,
        company_name: localUserInfo.company_name,
        department: localUserInfo.department,
        position: localUserInfo.position
      },
      email: localUserInfo.email,
      showKeyword: false,
      fieldList: []
    })

    const showForm = computed(() => {
      const hospatal = /医院/g
      const company = /公司/g
      if (hospatal.test(state.userInfo.company_name)) {
        return '1'
      } else if (company.test(state.userInfo.company_name)) {
        return '2'
      } else {
        return '3'
      }
    })

    watch(() => showForm.value, (val) => {
      if (val !== '') {
        state.userInfo.department = ''
        state.userInfo.position = ''
      }
    })

    const changeHandle = debounce((val) => {
      if (val !== '') {
        getCompanys({
          keyword: val
        }).then(res => {
          if (res.code === 200) {
            console.log(res)
            state.showKeyword = true
            state.fieldList = res.data
          }
        })
      } else {
        state.fieldList = []
        state.showKeyword = false
      }
    }, 200)

    const fieldClick = (item) => {
      state.userInfo.company_name = item.company_name
      state.showKeyword = false
    }

    const enums = new Map([
      ['1_name', '姓名不能为空'],
      ['1_company_name', '工作单位不能为空'],
      ['1_department', '科室不能为空'],
      ['1_position', '职称不能为空'],
      ['2_name', '姓名不能为空'],
      ['2_company_name', '工作单位不能为空'],
      ['2_department', '部门不能为空'],
      ['2_position', '职位不能为空'],
      ['3_name', '姓名不能为空'],
      ['3_company_name', '工作单位不能为空']
    ])

    const handleType = () => {
      let num = 0
      if (showForm.value === '3') {
        state.userInfo.department = ''
        state.userInfo.position = ''
        for (const item in { name: 'name', company_name: 'company_name' }) {
          const key = `${showForm.value}_${item}`
          if (!isDef(state.userInfo[item])) {
            num++
            Toast(enums.get(key))
            break
          }
        }
      } else {
        for (const item in state.userInfo) {
          const key = `${showForm.value}_${item}`
          if (!isDef(state.userInfo[item])) {
            num++
            Toast(enums.get(key))
            break
          }
        }
      }
      return num
    }

    const updateHandle = () => {
      if (handleType() === 0) {
        putUserBasic({
          ...deleteObjEmptyData(state.userInfo),
          company_type: showForm.value,
          email: state.email
        }).then(res => {
          console.log(res)
          if (res.code === 9) {
            Toast.fail(res.message)
          }
          if (res.code === 200) {
            Toast.success({
              message: '修改成功',
              onClose: () => {
                window.localStorage.setItem('userInfo', JSON.stringify(res.data))
                router.back()
              }
            })
          }
        })
      }
    }

    return {
      state,
      showForm,
      fieldClick,
      changeHandle,
      updateHandle,
      office,
      position,
      department,
      part
    }
  }
}
</script>

<style lang="less" scoped>
.account-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  padding: 26px 38px 0 38px;
  .account-item {
    margin-bottom: 36px;
    .field-list {
      transition: all 0.3s ease-in-out;
      margin-top: 4px;
      overflow: auto;
      width: 100%;
      box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.5);
      border-radius: 4px;
      .field-item {
        padding-left: 16px;
        font-size: 16px;
        color: #333333;
        line-height: 32px;
      }
    }
    .show {
      padding-top: 8px;
      max-height: 116px;
    }
    .hide {
      max-height: 0px;
    }
  }
  .btn-wrap {
    height: 44px;
    background: #007AFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
</style>
